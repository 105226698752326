import React from "react";
import { Progress } from "antd";

export function FallbackScreen() {
  const [color1, setColor1] = React.useState<string>("0%");
  const [color2, setColor2] = React.useState<string>("50%");
  const [strokeColor, setStrokeColor] = React.useState({});
  React.useEffect(() => {
    setColor1("0%");
    setColor2("50%");
    setInterval(() => {
      const v1 = color1;
      const v2 = color2;
      if (v1 == "0%") {
        setColor1("50%");
      } else {
        setColor1("0%");
      }
      if (v2 == "0%") {
        setColor2("100%");
      } else {
        setColor2("0%");
      }
      const element: any = {};
      element[color1] = "#0849b8";
      element[color2] = "#f1f1f1";
      setStrokeColor(element);
    }, 1500);
  }, []);
  return (
    <React.Fragment>
      <div className="yc-splash" style={{ background: "#0001" }}>
        <div style={{ width: "100%", position: "absolute", top: -8 }}>
          <Progress
            strokeColor={strokeColor}
            percent={99.9}
            showInfo={false}
            style={{ margin: 0, padding: 0 }}
            strokeLinecap="square"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
